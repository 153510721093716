<script lang="ts" setup>
import type { CmsElementProductGrid } from "~/types/cmsElementTypes";
import { useCmsElementConfig } from "#imports";
import { computed } from "vue";
import { useCmsUrlResolver } from "~/composables/useCmsUrlResolver";

const props = defineProps<{
  content: CmsElementProductGrid;
}>();
const { getConfigValue } = useCmsElementConfig(props.content);

const products = computed(() => props.content?.data?.products ?? []);

const title = computed(() => getConfigValue("title"));

let urlConfig = getConfigValue("url");
let { linkUrl, attr } = await useCmsUrlResolver(urlConfig);

const newTab = computed(() => getConfigValue("newTab"));
const linkLabel = computed(() => getConfigValue("linkLabel"));
</script>
<template>
  <div class="d-flex flex-column">
    <div v-if="title" class="text-h2 text-center mt-8 mb-16">
      {{ title }}
    </div>

    <v-row justify="center">
      <v-col
        v-for="product of products"
        :key="product.id"
        cols="12"
        sm="6"
        md="4"
      >
        <SwProductCard
          :layout-type="getConfigValue('boxLayout')"
          :product="product"
        />
      </v-col>
    </v-row>

    <v-btn
      v-if="urlConfig"
      :target="newTab ? '_blank' : '_self'"
      class="font-weight-bold mt-16 mx-auto"
      color="primary"
      size="large"
      :[attr]="linkUrl"
      variant="flat"
    >
      {{ linkLabel }}
    </v-btn>
  </div>
</template>
